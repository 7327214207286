import React, { useState, useRef, useEffect } from 'react';
import './App.css';

function App() {
  const [inputValue, setInputValue] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // For loader state
  const [isPlaying, setIsPlaying] = useState(false);  // For music playback

  const audioRef = useRef(null);  // Reference to the background music audio
  const confirmationSoundRef = useRef(null); // Reference to the confirmation sound audio

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    // If you had typing sound, you can include it here
  };

  const validateEmail = (email) => {
    // Simple email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!validateEmail(inputValue)) {
      setMessage('Ugyldig e-postadresse. Vennligst skriv inn en gyldig e-post.');
      return;
    }

    setIsLoading(true);  // Set loading state

    const emailData = {
      to: inputValue,
      message: 'None',  // You can replace this with a custom message if needed
    };

    try {

      // Stop the music if it's playing
      if (isPlaying && audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
      const response = await fetch('/.netlify/functions/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      const responseData = await response.json();  // Parse response JSON

      if (response.ok) {
        setMessage('Du hører fra oss');
        setInputValue('');  // Clear input field



        // Play the confirmation sound
        if (confirmationSoundRef.current) {
          confirmationSoundRef.current.currentTime = 0; // Reset to start
          confirmationSoundRef.current.play().catch((error) => {
            console.error('Confirmation sound playback failed:', error);
          });
        }

      } else {
        setMessage(`Enrolment failed: ${responseData.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Enrolment failed. Nettverksfeil.');
    } finally {
      setIsLoading(false);  // Reset loading state
    }
  };

  const toggleMusic = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error('Playback failed:', error);
        setMessage('Kunne ikke spille musikken. Vennligst prøv igjen.');
      });
    }
    setIsPlaying(!isPlaying);
  };

  // Attempt to autoplay the music when the component mounts
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error('Autoplay was prevented:', error);
        });
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {/* Audio Elements */}
        <audio ref={audioRef} src="/Robot Unicorn Attack Song.mp3" loop />
        <audio ref={confirmationSoundRef} src="/confirmation.mp3" />

        {/* Existing Content */}
        <p className="mainText">
          Føler du at samfunnet har et strupetak på din frihet og dine muligheter til å utforske ditt potensiale?<br />
          Er du begavet teknologisk, men jobber for tørre selskaper uten evne til å jakte ambisjoner?<br />
          Blir du aktivt begrenset av feigere menneskers selvrettferdige moralske kompass?<br /><br />
          Kompliserte tider krever kompliserte aktører. Bli med og knus systemet, ikke fall bak når vi bryter ned hyklernes murer!<br /><br />
        </p>

        {/* Form with Music Control Button Next to Email Submit Button */}
        <form onSubmit={sendEmail} className="form-container">
          <input
            className="input"
            type="email"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="E-post"
            required
          />

          <div className="button-group">
            <button className="button" type="submit" disabled={isLoading}>
              {isLoading ? 'Sender...' : 'Meld deg'}
            </button>
            <button type="button" onClick={toggleMusic} className="music-button">
              {isPlaying ? 'Kill the music' : 'I miss the music'}
            </button>
          </div>
        </form>

        {/* Message Display */}
        {message && <p>{message}</p>}
      </header>
    </div>
  );
}

export default App;
